.app-page {
    padding-bottom: 90px;
    height: 100%;
    h1 {
        font-weight: 600;
        font-size: 24px;
        line-height: 24px;
        margin-bottom: 0;
    }
    .app-sub-page {
        //max-width: 600px;
        height: 100%;
        margin: 0 auto;
        //padding-left: 23px;
        //padding-right: 23px;
    }
    .pageHeader, .container {
        padding-top: 15px;
        padding-bottom: 15px;
        max-width: 600px;
        margin: 0 auto;
        padding-left: 23px;
        padding-right: 23px;
        &.subPageHeader {
            h1 {
                text-align: left;
                margin-top: 12px;
            }
        }
    }
    &.search {
        .search-box {
            max-width: 600px;
            margin: 16px auto 0;
            padding-left: 23px;
            padding-right: 23px;
            fieldset {
                border: none;
            }
            .search-input {
                width: 100%;
                border: none;
                background: transparent;
                margin-left: 10px;
                font-weight: 400;
                font-size: 16px;
                letter-spacing: 0.025em;
                color: #757575;
                &:focus {
                    outline: none;
                }
            }
            .search-input-wrapper {
                background-color: #F2F2F2;
                border-radius: 12px;
                padding: 12px 12px 12px 14px;
            }
        }
        .collection-grid {
            h3 {
                text-transform: none;
                font-weight: 600;
                font-size: 18px;
                text-align: left;
                margin-top: 39px;
            }
            .collection-grid-container {
                margin: 0 -4px;
            }
            .collection-grid-item {
                flex-basis: 50%;
                padding: 4.5px;
                .collection-grid-btn {
                    padding: 26px;
                    background-color: #F8F8F8;
                    border-radius: 12px;
                    width: 100%;
                    border: none;
                    animation: none !important;
                    img.collection-grid-image {
                        width: 100%;
                        border-radius: 12px;
                        object-fit: cover;
                        mix-blend-mode: multiply;
                    }
                }
                p {
                    margin-bottom: 7px;
                    margin-top: 8px;
                    font-weight: 600;
                    font-size: 16px;
                    text-align: left;
                }
            }
        }
    }
}

.loadingSpinner svg {
    color: #111111;
}

.loadingSpinner.light svg {
    color: #ffffff;
}