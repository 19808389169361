.collections.app-page {
    height: 100vh;
    .collection-grid {
        .collection-grid-container {
            margin: 0 -4px;
        }
        .collection-grid-item {
            flex-basis: 50%;
            padding: 4px;
            img.collection-grid-image {
                width: 100%;
                height: 125px;
                border-radius: 12px;
                object-fit: cover;
            }
            div.collection-grid-image-header {
                width: 87%;
                margin-bottom: 25px;
                margin-top: 25px;
            }
        }
        .collection-grid-btn {
            padding: 0;
            width: 100%;
            border: none;
            animation: none !important;
            &:hover {
                background-color: transparent;
            }
            h2 {
                position: absolute;
                text-transform: none;
                font-weight: 600;
                font-size: 18px;
                bottom: 12px;
                left: 12px;
                margin-bottom: 0;
            }
        }
        &.brands {
            .collection-grid-item {
                flex-basis: 50%;
                display: flex;
                justify-content: center;
                &.collection-grid-item-header {
                    flex-basis: 100%;
                }
                div.collection-grid-image-header {
                    img {
                        max-height: 100px;
                        max-width: 300px;
                    }
                }
                &.brand-header {
                    div.collection-grid-image-header {
                        width: 30%;
                        flex: 0 0 40%;
                        display: flex;
                        &:first-of-type {
                            padding-right: 20px;
                            border-right: 1px solid #FFFFFF;
                            justify-content: flex-end;
                        }
                        &:last-of-type {
                            padding-left: 20px;
                            border-left: 1px solid #FFFFFF;
                            justify-content: flex-start;
                        }
                        img {
                            width: auto;
                            max-width: 100%;
                            object-fit: contain;
                            max-height: 70px;
                        }
                    }
                }
                @media (min-width: 801px) {
                    div.collection-grid-image-header {
                        margin-bottom: 50px;
                    }
                }
            }
        }
    } 

    .app-sub-page.products {
        padding-bottom: 90px;
        &.product {
            .collection-grid {
                background-color: #FFFFFF;
                border-radius: 12px;
                padding: 16px;
            }
        }
        .collection-grid {
            .collection-grid-item {
                flex-basis: 100%;
                padding-bottom: 20px;
                img.product-image {
                    mix-blend-mode: multiply;
                    width: 100%;
                }
                h3 {
                    font-weight: 600;
                    font-size: 18px;
                    line-height: 23px;
                    text-transform: none;
                    color: #000000;
                    text-align: left;
                    margin-bottom: 5px;
                }
                p {
                    font-weight: 400;
                    font-size: 16px;
                    line-height: 22px;
                    text-transform: none;
                    text-align: left;
                    color: #000000;
                }
                .collection-grid-btn.add-save {
                    width: 36px;
                    height: 36px;
                    svg {
                        height: 100%;
                        width: 100%;
                    }
                    &.is-saved {
                        svg {
                            transform: rotate(45deg)
                        }
                    }
                }
            }
        }
        .product-card {
            background-color: #FFFFFF;
            border-radius: 12px;
            padding: 16px;
            display: block;
            cursor: pointer;
            p {
                font-weight: 400;
                font-size: 14px;
                line-height: 18px;
                text-transform: none;
                text-align: left;
                color: #757575;
                margin-bottom: 0;
            }
        }
        .product-swatch-label {
            margin-bottom: 13px;
        }
        .product-swatch-options {
            .product-swatch-option {
                .product-swatch-btn {
                    width: 20px;
                    height: 20px;
                    margin: 3px;
                    border-radius: 100%;
                }
                border: 2px solid #EDEDED;
                border-radius: 100%;
                margin-right: 5px;
                &.selected {
                    border-color: #000000;
                }
            }
        }
        .sayduck-3d-viewer-container, .sayduck-3d-viewer-container2 {
            display: none;
        }
    }
}

.product-features {
   h2 {
    font-weight: 700;
    font-size: 16px;
    line-height: 23px;
    text-align: left;
   }
   ul {
       text-align: left;
   }
}

.page-wrapper-brands {
    background-color: #000000;
}

.background-color-wrapper {
    height: 100%;
}

.collection-grid {
    max-width: 600px;
    margin: 0 auto;
    padding-left: 23px;
    padding-right: 23px;
}