.app-page.profile {
    .MuiTabs-root {
        min-height: unset;
        margin-bottom: 25px;
    }
    .profile-grid-container {
        margin-bottom: 15px;
    }
    .MuiGrid-item {
        p {
            font-size: 14px;
            line-height: 18px;
            color: #000000;
            font-weight: 600;
            margin-bottom: 0;
        }
    }
    .MuiTabs-flexContainer {
        margin-bottom: 4px;
    }
    .MuiTab-root.profile-tab-btn {
        padding: 0;
        text-align: left;
        min-width: unset;
        color: rgba(0, 0, 0, 0.35);
        font-weight: 600;
        font-size: 18px;
        line-height: 23px;
        min-height: unset;
        text-transform: none;
        &:first-of-type {
            margin-right: 16px;
        }
        &.Mui-selected {
            color: #000000;
        }
    }
    .MuiTabs-indicator {
        background-color: #000000;
    }
    .collection-grid-btn {
        padding: 0;
        width: 100%;
        border: none;
        animation: none !important;
        &:hover {
            background-color: transparent;
        }
        &.action {
            background-color: #F8F8F8;
            border-radius: 100%;
        }
        &:first-of-type {
            margin-right: 8px;
        }
        &.profile {
            width: 75px;
            padding: 15px 10px;
            background-color: #F8F8F8;
            border-radius: 12px;
            margin-right: 12px;
        }
    }
    img.profile-image {
        mix-blend-mode: multiply;
    }
}

.profile-image-wrapper {
    width: 75px;
    padding: 15px 10px;
    background-color: #F8F8F8;
    border-radius: 12px;
    margin-right: 12px;
    img {
        width: 100%;
        mix-blend-mode: multiply;
    }
}