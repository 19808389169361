.App {
  text-align: center;
  height: 100%;
}

.App-logo {
  pointer-events: none;
  opacity: 0;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: 5s 2s App-logo-spin;
  }
}

.App-header {
  background-color: #000000;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
  .home-image-header {
    margin-top: 0 !important;
  }
}



@keyframes App-logo-spin {
  0% { opacity: 0; }
  50%   { opacity: 1; }
  100% { opacity: 0; }
}
