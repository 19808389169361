body, div {
  -ms-overflow-style: none; 
    scrollbar-width: none; 
    overflow-y: scroll; 
}

body::-webkit-scrollbar, div::-webkit-scrollbar {
  display: none;
}

body {
  margin: 0;
  font-family: 'Futura PT', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
a.MuiButton-root {
  display: block;
  text-decoration: none;
  text-transform: uppercase;
}

button.MuiButton-root, a.MuiButton-root {
  min-width: unset;
  color: #ffffff;
  border: 2px solid #ffffff;
  border-radius: 50px;
  padding: 18px 34px;
  font-size: 15px;
  line-height: 19px;
  font-weight: 600;
  &.product {
    color: #000000;
    border-color: #000000;
    padding: 14px 26px;
    font-size: 12px;
    line-height: 15px;
    width: 100%;
    &:hover {
      background-color: #f8f8f8;
      border: 2px solid #000000;
    }
    &.sayduck-tryon {
      position: absolute;
      bottom: 16px;
      left: 16px;
      z-index: 999;
      background-color: #FFFFFF;
      width: auto;
    }
  }
  &.addToCollection {
    svg {
      width: 29px;
      height: 29px;
    }
  }
  &.menu-button {
    border: none;
    display: inline-flex;
    flex-direction: column;
    padding: 14px 7px 21px;
    border-radius: 0;
    width: 100%;
    .menu-button-text {
      font-size: 15px;
      line-height: 19px;
      font-weight: 400;
      margin-top: 8px;
      text-transform: none; 
    }
    &:hover {
      background-color: inherit;
    }
  }
  &.settings-icon-btn {
    position: relative;
    padding: 0;
    border: none;
    &:hover {
      background-color: inherit;
    }
    .update-cirle {
      width: 10px;
      height: 10px;
      background-color: red;
      border-radius: 100%;
      position: absolute;
      top: 0;
      right: 0;
    }
  }
  .MuiTouchRipple-root {
    display: none;
  }
  &.homeBtn {
    border-radius: 0;
    color: #ffffff;
    min-height: 100%;
    border: none;
    display: block;
    &:hover {
      background-color: transparent;
    }
  }
  &.Mui-disabled {
    border-color: rgba(0,0,0,.25);
    color: rgba(0,0,0,.25);
    border-width: 2px;
  }
  &.MuiButton-text.policy {
    color: rgba(0, 0, 0, 0.87);
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    padding: 0;
    background: transparent;
    border: none;
    text-transform: none;
    margin-top: 12px;
    svg {
      height: 12px;
      margin-right: 12px;
    }
  }
}

.settingsDialog {
  .MuiDialog-paper {
    background-color: transparent;
    box-shadow: none;
    width: 100%;
    max-width: 600px;
    margin-left: 25px;
    margin-right: 25px;
    padding-bottom: 68px;
    .settingsDialogContent {
      background-color: #ffffff;
      border-radius: 25px;
      width: 100%;
      height: 100%;
      padding-left: 38px;
      padding-right: 38px;
      h2 {
        text-align: center;
        font-weight: 600;
        font-size: 24px;
        line-height: 31px;
        margin-top: 20px;
      }
      h3 {
        font-weight: 600;
        font-size: 18px;
        line-height: 23px;
      }
      h4 {
        font-weight: 600;
        font-size: 16px;
        line-height: 22px;
      }
      p {
        font-weight: 400;
        font-size: 16px;
        line-height: 22px;
      }
      button.product {
        margin-top: 10px;
      }
    }
    .close-icon-btn {
      margin-bottom: 10px;
      padding: 0;
      border: none;
      align-self: flex-end;
      width: 15px;
      &:hover {
        background-color: inherit;
      }
    }
  }
}

.settingsDialog + .settingsDialog .MuiBackdrop-root {
  display: none;
}

.sayduck-3d-viewer-153 {
  overflow: initial !important;
}

.sayduck-3d-viewer-240 > svg {
  width: 150px;
  height: 150px;
}

#blankVideo {
  position: fixed;
  top: 0;
  left: -99999999px;
}