#home {
    height: 100vh;
    display: flex;
    flex-direction: column;
    .backgroundOverlay {
        position: fixed;
        bottom: 90px;
        left: 0;
        right: 0;
        img {
            width: 100%;
            max-width: 600px;
        }
    }
    .homeFakeBtn {
        padding: 5px 10px;
        margin-top: 30px;
        margin-left: auto;
        margin-right: auto;
        font-size: 85%;
        letter-spacing: 3px;
        font-weight: bold;
        max-width: 300px;
        &:first-of-type {
            padding: 5px 15px;
        }
        &.install {
            background-color: #FFFFFF;
            color: #000000;
            position: absolute;
            left : 50%;
            transform: translateX(-50%);
            z-index: 99999;
        }
        @media (min-width: 801px) {
            font-size: 100%;
        }
    }
}

#installInstructions {
    display: none
 }
 @media (display-mode: browser) {
    #installInstructions {
      display: flex
    }
 }

 .home-image-header {
    display: flex;
    justify-content: center;
    width: 95%;
    max-width: 500px !important;
    margin: 0 auto;
    margin-top: 90px !important;
    @media (min-width: 801px) {
        margin-top: 120px !important;
    }
    &.no-brand-image {
        div {
            width: 100%;
            max-width: 150px;
            @media (min-width: 801px) {
                max-width: 300px;
            }
        }
    }
    &.with-brand-image {
        div {
            width: 48%;
            flex: 0 0 48%;
            display: flex;
            &:first-of-type {
                padding-right: 20px;
                border-right: 1px solid #FFFFFF;
                justify-content: flex-end;
            }
            &:last-of-type {
                padding-left: 20px;
                border-left: 1px solid #FFFFFF;
                justify-content: flex-start;
            }
            @media (min-width: 801px) {
                width: 50%;
                flex: 0 0 50%;
            }
        }
    }
    img {
        width: auto;
        max-width: 100%;
        object-fit: contain;
        max-height: 100px;
    }
}